import { useEffect } from "react";
import Head from "next/head";

import { Layout } from "modules/common";
import { SwapForm } from "modules/swap";

export default function SwapPage() {
  return (
    <Layout>
      <Head>
        <title>Dexter Swap</title>
      </Head>
      <SwapForm />
    </Layout>
  );
}
